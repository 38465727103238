import { VictoryPie, VictoryTheme, VictoryContainer } from "victory";

export default function TortaSpese({ data }){
  let newData = [...data];
  newData = JSON.stringify(newData).replaceAll("costoSpesa", "y").replaceAll("nomeSpesa", "x");
  newData = JSON.parse(newData);
  newData.forEach((spesa) => {
    spesa.x = spesa.x.split(" ");
    spesa.y = parseFloat(spesa.y) / parseFloat(spesa.cadenza);
  })
  console.log('newData', newData);
  return(
      <VictoryPie 
        padAngle={({datum}) => datum.y } 
        innerRadius = { 40 } 
        radius={ 60 } 
        labels={({ datum }) => datum.x } 
        labelRadius={({ radius }) => radius + 5 } 
        width={200} 
        height={200} 
        theme={VictoryTheme.material} 
        style={{ labels: { fontSize: 8, fontWeight: "bold" }}} 
        data={newData} 
        containerComponent={<VictoryContainer style={{ touchAction: "auto" }}/>}
      />
  )
}
