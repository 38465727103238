import { Row } from "react-bootstrap";
import CardSpesa from "./CardSpesa";
import { v4 as uuid } from 'uuid';

export default function ListaSpese({ listaSpese, removeEffect }){

  return(
  <>
      <Row style={{ justifyContent: "space-around" }}>
    { listaSpese.map((spese, index) => ( 
          <CardSpesa key={uuid()} spesa={spese} removeCard={removeEffect}/>
    ))}
      </Row>
    </>
  ); 
}
