import { Row, Col } from "react-bootstrap";

export default function TotaleSpese({ totaleSpese }){
  const totaleSpeseMensili = totaleSpese.map(spesa => ({...spesa}));
  const totaleSpeseAnnuali = totaleSpese.map(spesa => ({...spesa}));

  totaleSpeseMensili.forEach((spese) => {
    console.log('prima', spese.costoSpesa);
    spese.costoSpesa = spese.costoSpesa / spese.cadenza;
    console.log('dopo', spese.costoSpesa);
  })
  console.log('mensili', totaleSpeseMensili);
  const totaleSpeseMensiliSum = totaleSpeseMensili.reduce((totale, spesa) => totale = totale + spesa.costoSpesa, 0);
  console.log('totMens', totaleSpeseMensiliSum);

  totaleSpeseAnnuali.forEach((spese) => {
    spese.costoSpesa = parseFloat(spese.costoSpesa) * parseInt(12 / spese.cadenza);
  })
  console.log('annuali', totaleSpeseAnnuali);
  const totaleSpeseAnnualiSum = totaleSpeseAnnuali.reduce((totale, spesa) => totale = totale + spesa.costoSpesa, 0);
  console.log('totAnn', totaleSpeseAnnualiSum);

  return(
    <>
      <Row >
        <Col xs={4}>
          Totale Mensile: 
        </Col>
        <Col xs={6}>
          € { parseFloat(totaleSpeseMensiliSum).toFixed(2).replace(".", ",") }
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          Totale Annuali:
        </Col>
        <Col xs={6}>
          € { parseFloat(totaleSpeseAnnualiSum).toFixed(2).replace(".", ",") }
        </Col>
      </Row>
    </>
  )
}
