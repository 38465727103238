import { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ListaSpese from "./ListaSpese";
import { v4 as uuid } from "uuid";
import TortaSpese from './TortaSpese';
import TotaleSpese from './TotaleSpese';

export default function FormSpesa(){
  const storageKey = "spesa-io";

  const [spese, setSpese] = useState(() => {
    const saved = localStorage.getItem(storageKey);
    if(saved) {
      const parsed = JSON.parse(saved);
      return parsed;
    }
    else {
      return [];
    }
  });
  const [nomeSpesa, setNomeSpesa] = useState("");
  const [costoSpesa, setCostoSpesa] = useState("");
  const [totaleSpesa, setTotaleSpesa] = useState(0);
  const [cadenza, setCadenza] = useState("");
  const [validated, setValidated] = useState(false);

  //get storage on load
  useEffect(() => {
    const saved = localStorage.getItem(storageKey);
    if(saved) {
      const parsed = JSON.parse(saved);
      setSpese(parsed);
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(spese))
    updateTotaleSpesa();
  }, [spese])
  
  function handleChangeNomeSpesa(event){
    setNomeSpesa(event.target.value);
  }

  function handleChangeCostoSpesa(event){
    setCostoSpesa(event.target.value);
  }

  function handleChangeCadenza(event){
    setCadenza(event.target.value);
  }

  function emptyNomeSpesa(){
    setNomeSpesa("");
  }

  function emptyCostoSpesa(){
    setCostoSpesa("");
  }

  function emptyCadenza(){
    setCadenza("");
  }

  function handleAddSpesa(){
    setSpese((prevSpese) =>{
      return([...prevSpese, { id: uuid(),  nomeSpesa: nomeSpesa, costoSpesa: costoSpesa.replace(",","."), cadenza: cadenza }]);
    })
    emptyNomeSpesa();
    emptyCostoSpesa();
    emptyCadenza();
  }

  function onFormSpesaSubmit(e){
    e.preventDefault();
    const form = e.currentTarget;
    if(form.checkValidity() === false){
      e.stopPropagation();
    }
    else {
      handleAddSpesa();
    }
  }
  
  function updateTotaleSpesa(){
    const newTotale = spese.reduce((totale, spesa) => parseFloat(totale) + parseFloat(spesa.costoSpesa), 0);
    setTotaleSpesa(newTotale);
  }

  function handleRemoveCard(id){
    const newSpese = spese.filter(spesa => spesa.id !== id);
    setSpese(newSpese);
  }
  

  return(
    <Container style={{ justifyContent: "center" }}>
      <Row style={{ justifyContent: "center" }} className="mt-3">
        <Col xs={10} sm={6}>
          <Form onSubmit={onFormSpesaSubmit} noValidated validated={validated}>
            <Form.Group className="mb-3">
              <Form.Label>Nome Spesa</Form.Label>
              <Form.Control required placeholder="Inserisci nome spesa" value={nomeSpesa} id="nomeSpesa" onChange={handleChangeNomeSpesa} />
              <Form.Control.Feedback type="invalid">
                Inserisci il nome della spesa
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Costo Spesa</Form.Label>
              <Form.Control required placeholder="Inserisci costo spesa" value={costoSpesa} id="costoSpesa" onChange={handleChangeCostoSpesa} />
              <Form.Control.Feedback type="invalid">
                Inserisci il costo della spesa
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Cadenza</Form.Label>
              <Form.Select required id="cadenza" onChange={handleChangeCadenza} value={cadenza}>
                <option value="">Seleziona una cadenza</option>
                <option value="1">Mensile</option>
                <option value="2">Bimestrale</option>
                <option value="3">Trimestrale</option>
                <option value="6">Semestrale</option>
                <option value="12">Annuale</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Devi selezionare una cadenza
              </Form.Control.Feedback>
            </Form.Group>
            <Button type="submit">Aggiungi spesa</Button>
          </Form>
        </Col>
        <Col xs={10} sm={6}>
          <TortaSpese data={spese} />
        </Col>
      </Row>
      <hr />
      <TotaleSpese totaleSpese={spese} />
      <hr />
      <div>Lista Spese</div>
      <ListaSpese listaSpese={spese} removeEffect={handleRemoveCard} />
    </Container>
  );
}
