import { Row, Col, Card, Button } from "react-bootstrap";

export default function CardSpesa({ spesa, removeCard }){

  function removeCardId(){
    removeCard(spesa.id);
  }

  function cadenzaTranslate(cadenza){
    switch(cadenza){
      case "1":
        return "Mensile";
        break;
      case "2":
        return "Bimestrale";
        break;
      case "3":
        return "Trimestrale";
        break;
      case "6":
        return "Semestrale";
        break;
      case "12":
        return "Annuale";
        break;
    }
  }

  return(
    <Col className="mt-3" xs={10} sm={6} md={6} lg={4} xl={3} >
      <Card key={spesa.id}>
        <Card.Body>
          <Card.Title style={{ fontSize: 18 }}>
            <Row>
              <Col xs={8}>
                {spesa.nomeSpesa}
              </Col>
              <Col xs={4} style={{ textAlign: "right" }}>
                <Button onClick={removeCardId} variant="outline-secondary" size="sm">X</Button>
              </Col>
            </Row>
          </Card.Title>
          <Card.Text style={{ fontSize: 14 }}>
            <Row>
              <Col xs={4}>
                Costo: 
              </Col>
              <Col xs={8}>
                {parseFloat(spesa.costoSpesa).toFixed(2).replace(".",",")} €
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                Cadenza 
              </Col>
              <Col xs={8}>
                {cadenzaTranslate(spesa.cadenza)}
              </Col>
            </Row>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  )
}
